import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@mui/material';

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import NextPlanIcon from '@mui/icons-material/NextPlan';

function StravaActivityStatusIcon({ run, i, fontSize }) {
  const selectIconComponent = () => {
    if (run.metTarget)
      return <CheckCircleIcon color="success" fontSize={fontSize} />;
    if (run.skipped)
      return <NextPlanIcon color="warning" fontSize={fontSize} />;
    if (run.timeInMins)
      return <CircleIcon color="success" fontSize={fontSize} />;
    return <CircleOutlinedIcon fontSize={fontSize} />;
  };

  // const runCompleted = !!run.timeInMins;

  return (
    <Tooltip
      key={i}
      title={`${run.targetRange[0]} - ${run.targetRange[1]} mins`}
      enterTouchDelay={0}
      arrow
      placement="top"
    >
      {selectIconComponent()}
    </Tooltip>
  );
}

StravaActivityStatusIcon.propTypes = {
  run: PropTypes.shape({
    timeInMins: PropTypes.number,
    metTarget: PropTypes.bool,
    targetRange: PropTypes.arrayOf(PropTypes.number),
    skipped: PropTypes.bool
  }).isRequired,
  i: PropTypes.number.isRequired,
  fontSize: PropTypes.string
};

StravaActivityStatusIcon.defaultProps = {
  fontSize: 'small'
};

export default StravaActivityStatusIcon;
