import React, { useState } from 'react';

import {
  TextField,
  Select,
  MenuItem,
  Button,
  Paper,
  Stack,
  FormControl,
  InputLabel
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import Markdown from 'react-markdown';

import { generateText } from '../api/llms';

function LLM() {
  const [model, setModel] = useState('gemini-1.5-flash-latest');
  const [prompt, setPrompt] = useState(null);
  const [output, setOutput] = useState(null);

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={3}
      size={{
        xs: 12,
        md: 6
      }}
    >
      <Grid size={12} container direction="column" spacing={2}>
        <Grid size={12}>
          <Paper>
            <FormControl variant="standard" sx={{ paddingY: 3, width: '90%' }}>
              <Stack spacing={2}>
                <InputLabel id="llm-select-label" label="Model" />
                <Select
                  id="llm-select"
                  labelId="llm-select-label"
                  label="Model"
                  variant="outlined"
                  value={model}
                  onChange={(event) => {
                    setModel(event.target.value);
                  }}
                >
                  <MenuItem value="gemini-1.5-flash-latest">
                    Gemini 1.5 Flash
                  </MenuItem>
                  <MenuItem value="gpt-4o">GPT-4o</MenuItem>
                </Select>
                <TextField
                  label="Prompt"
                  variant="outlined"
                  minRows={3}
                  multiline
                  fullWidth
                  onChange={(event) => {
                    setPrompt(event.target.value);
                  }}
                />
                <Button
                  variant="contained"
                  onClick={async () => {
                    console.log(model);
                    console.log(prompt);
                    const result = await generateText(model, undefined, prompt);
                    setOutput(result);
                  }}
                >
                  Go!
                </Button>
              </Stack>
            </FormControl>
          </Paper>
        </Grid>
        {output && (
          <Grid size={12}>
            <Paper sx={{ padding: 5 }}>
              <Markdown>{output}</Markdown>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default LLM;
