import React from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
  Select,
  MenuItem,
  Button,
  Paper,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import moment from 'moment';

class TogglBook extends React.Component {
  constructor(props) {
    super(props);

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.submitForm = this.submitForm.bind(this);

    this.state = {
      date: moment().format('YYYY-MM-DD'),
      holidayType: 'booked',
      time: 'day'
    };
  }

  handleDateChange(event) {
    this.setState({
      date: event.target.value
    });
  }

  handleTypeChange(event) {
    this.setState({
      holidayType: event.target.value
    });
  }

  handleTimeChange(event) {
    this.setState({
      time: event.target.value
    });
  }

  submitForm() {
    const { onSubmit } = this.props;
    if (onSubmit) onSubmit(this.state);
  }

  render() {
    const { style } = this.props;
    const { date, holidayType, time } = this.state;

    return (
      <Paper style={style}>
        <Grid container spacing={3}>
          <Grid size={12}>
            <Typography variant="h5">Holiday</Typography>
          </Grid>
          <Grid size={12}>
            <TextField
              id="datePicker"
              label="Date"
              type="date"
              value={date}
              // className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              onChange={this.handleDateChange}
            />
          </Grid>
          <Grid size={12}>
            <Select
              id="holidayTypePicker"
              value={holidayType}
              onChange={this.handleTypeChange}
            >
              <MenuItem value="booked">Booked Holiday</MenuItem>
              <MenuItem value="bank">Bank Holiday</MenuItem>
            </Select>
          </Grid>
          <Grid size={12}>
            <Select
              id="timePicker"
              value={time}
              onChange={this.handleTimeChange}
            >
              <MenuItem value="day">Whole Day</MenuItem>
              <MenuItem value="am">Morning</MenuItem>
              <MenuItem value="pm">Afternoon</MenuItem>
            </Select>
          </Grid>
          <Grid size={12}>
            <Button variant="contained" onClick={this.submitForm}>
              <Typography>Submit</Typography>
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

TogglBook.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  style: PropTypes.shape({
    height: PropTypes.string,
    padding: PropTypes.string
  }).isRequired
};

export default TogglBook;
