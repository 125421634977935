import React, { useState } from 'react';

import { Helmet } from 'react-helmet';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {
  ThemeProvider,
  createTheme,
  alpha,
  getContrastRatio
} from '@mui/material/styles';

import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PetsIcon from '@mui/icons-material/Pets';
import WorkIcon from '@mui/icons-material/Work';
import DeskIcon from '@mui/icons-material/Desk';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import TimerIcon from '@mui/icons-material/Timer';
import NightlightRoundIcon from '@mui/icons-material/NightlightRound';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import { teal, blue } from '@mui/material/colors';

import Navigation from './components/navigation';

import Home from './pages/home';
import Desk from './pages/office';
// import Inky from './pages/inky';
import Health from './pages/health';
import Strava from './pages/strava';
import Toggl from './pages/toggl';
import FitBark from './pages/fitbark';
// import Cam from './pages/cam';
// import Coral from './pages/coral';
import Drone from './pages/drone';
import LLM from './pages/llm';
import Astro from './pages/astro';
import Car from './pages/car';
import Training from './pages/training';
import Account from './pages/account';

import { getApiToken } from './api/base';
import { getUserPages } from './api/user';

const fitbitBase = '#002A3A';
const stravaBase = '#FC5200';
const fitbarkBase = '#38B7C3';
const threadsBase = '#000000';

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  palette: {
    primary: teal,
    secondary: blue,
    fitbark: {
      main: alpha(fitbarkBase, 0.7),
      light: alpha(fitbarkBase, 0.5),
      dark: alpha(fitbarkBase, 0.9),
      contrastText:
        getContrastRatio(alpha(fitbarkBase, 0.7), '#fff') > 4.5
          ? '#fff'
          : '#111'
    },
    fitbit: {
      main: alpha(fitbitBase, 0.7),
      light: alpha(fitbitBase, 0.5),
      dark: alpha(fitbitBase, 0.9),
      contrastText:
        getContrastRatio(alpha(fitbitBase, 0.7), '#fff') > 4.5 ? '#fff' : '#111'
    },
    strava: {
      main: alpha(stravaBase, 0.7),
      light: alpha(stravaBase, 0.5),
      dark: alpha(stravaBase, 0.9),
      contrastText:
        getContrastRatio(alpha(stravaBase, 0.7), '#fff') > 4.5 ? '#fff' : '#111'
    },
    threads: {
      main: alpha(threadsBase, 0.7),
      light: alpha(threadsBase, 0.5),
      dark: alpha(threadsBase, 0.9),
      contrastText:
        getContrastRatio(alpha(threadsBase, 0.7), '#fff') > 4.5
          ? '#fff'
          : '#111'
    }
  }
});

const styles = {
  content: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // justifyContent: 'center',
    minHeight: 'calc(100vh - 56px - (2 * 20px))',
    // width: '100%',
    // height: '100%',
    // overflowX: 'none',
    padding: '20px',
    backgroundColor: '#fafafa'
  }
};

const allPages = [
  { name: 'Home', address: '/home', component: Home, icon: HomeIcon },
  { name: 'Health', address: '/health', component: Health, icon: FavoriteIcon },
  { name: 'Dog', address: '/dog', component: FitBark, icon: PetsIcon },
  { name: 'Work', address: '/work', component: Toggl, icon: WorkIcon },
  { name: 'Office', address: '/office', component: Desk, icon: DeskIcon },
  {
    name: 'Strava',
    address: '/strava',
    component: Strava,
    icon: DirectionsRunIcon
  },
  {
    name: 'Training',
    address: '/training',
    component: Training,
    icon: TimerIcon
  },
  {
    name: 'Astronomy',
    address: '/astronomy',
    component: Astro,
    icon: NightlightRoundIcon
  },
  { name: 'Car', address: '/car', component: Car, icon: DirectionsCarIcon },
  {
    name: 'Drone',
    address: '/drone',
    component: Drone,
    icon: VideogameAssetIcon
  },
  { name: 'LLMs', address: '/llm', component: LLM, icon: AutoAwesomeIcon },
  // { name: "Inky", address: "/inky", component: Inky },
  // { name: "Cam", address: "/cam", component: Cam },
  // { name: "Coral", address: "/coral", component: Coral },
  {
    name: 'Account',
    address: '/account',
    component: Account,
    icon: VerifiedUserIcon
  }
];

const processUserPages = async () => {
  try {
    const data = await getUserPages();

    let userPages = [];

    if (data.pages === '*') {
      userPages = allPages;
    } else if (Array.isArray(data.pages)) {
      userPages = allPages.filter((p) => data.pages.includes(p.name));
    }

    return userPages;
  } catch (err) {
    console.error(err);
    return [];
  }
};

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [pages, setPages] = useState([]);

  getApiToken();

  let title = 'Apps';

  const filterPages = async (isLoggedIn) => {
    if (!isLoggedIn) {
      if (pages.length > 0) setPages([]);
      return;
    }

    const userPages = await processUserPages();
    setPages(userPages);
  };

  const processTitle = (path) => {
    if (loggedIn && pages) {
      const filteredPages = pages.filter(
        (p) => p.address === path ?? window.location.pathname
      );
      if (filteredPages.length > 0) {
        title = `${filteredPages[0].name} - nickstringer.dev`;
      }
    } else {
      title = 'nickstringer.dev';
    }
  };

  const googleLoggedIn = (gLoggedIn) => {
    console.log('Google logged in', gLoggedIn);
    setLoggedIn(gLoggedIn);
    filterPages(gLoggedIn);
  };

  processTitle();

  const setupCSP = () => {
    const csp = {
      'default-src': "'self' accounts.google.com",
      'connect-src':
        "'self' ws://192.168.0.96 accounts.google.com api.mapbox.com events.mapbox.com *.openstreetmap.org *.cartocdn.com cloudflareinsights.com",
      'frame-src': "'self' accounts.google.com",
      'script-src':
        "'self' accounts.google.com ajax.cloudflare.com static.cloudflareinsights.com blob: 'unsafe-inline'",
      'img-src':
        "'self' *.openstreetmap.org *.cartocdn.com *.cloudfront.net data:",
      'media-src': "'self'  *.cartocdn.com",
      'style-src':
        "'self' accounts.google.com api.tiles.mapbox.com *.cartocdn.com 'unsafe-inline'",
      'style-src-elem':
        "'self' accounts.google.com api.tiles.mapbox.com *.cartocdn.com 'unsafe-inline'"
    };

    if (process.env.NODE_ENV === 'development') {
      const apiHost = '192.168.0.96';

      csp['connect-src'] += ` ${apiHost}`;
    }

    return Object.entries(csp)
      .map(([key, value]) => `${key} ${value};`)
      .join('');
  };

  console.log(`Logged in: ${loggedIn}`);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Navigation
          pages={pages}
          handleAuthChange={(newState) => {
            if (newState !== loggedIn) googleLoggedIn(newState);
          }}
        />
        <Helmet>
          <meta httpEquiv="Content-Security-Policy" content={setupCSP()} />
          <title>{title}</title>
        </Helmet>
        {loggedIn ? (
          <div style={styles.content}>
            <Switch>
              {pages.map((page) => (
                <Route
                  key={page.name.replaceAll(' ', '')}
                  path={page.address}
                  component={page.component}
                />
              ))}
            </Switch>
          </div>
        ) : null}
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
