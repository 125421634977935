/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Paper,
  Badge,
  Divider,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

import { startOfMonth, add, parseJSON, getUnixTime, format } from 'date-fns';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBicycle,
  faRunning,
  faWalking
} from '@fortawesome/free-solid-svg-icons';

import { getActivities } from '../api/strava';

const activityIcons = {
  Run: <FontAwesomeIcon icon={faRunning} />,
  Walk: <FontAwesomeIcon icon={faWalking} />,
  Ride: <FontAwesomeIcon icon={faBicycle} />
};

function BadgedPickersDay({ activities, day, outsideCurrentMonth, ...other }) {
  const dateFormat = 'yyyy-MM-dd';
  const thisDayActivities = activities.filter(
    (a) => format(a.start_date, dateFormat) === format(day, dateFormat)
  );
  let content = null;

  if (!outsideCurrentMonth && thisDayActivities.length > 0) {
    if (thisDayActivities.length === 1) {
      const activity = thisDayActivities[0];
      content = activityIcons[activity.type];
    } else {
      content = thisDayActivities.length;
    }
  }

  return (
    <Badge badgeContent={content} color="secondary" sx={{ zIndex: 5 }}>
      <PickersDay
        {...other}
        day={day}
        outsideCurrentMonth={outsideCurrentMonth}
      />
    </Badge>
  );
}

BadgedPickersDay.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.any).isRequired,
  day: PropTypes.any.isRequired,
  outsideCurrentMonth: PropTypes.bool.isRequired
};

function StravaActivityPicker({ onSelect }) {
  const [activities, setActivities] = useState([]);
  const [dayActivities, setDayActivities] = useState([]);
  const [chosenDate, setChosenDate] = useState(new Date());

  const now = new Date();
  const startOfThisMonth = startOfMonth(now);

  const handleDateChange = (date) => {
    const dateFormat = 'yyyy-MM-dd';
    const newDayActivities = activities.filter(
      (a) => format(a.start_date, dateFormat) === format(date, dateFormat)
    );

    setChosenDate(date);
    setDayActivities(newDayActivities);

    // if (dayActivities.length === 1) {
    //   let a = dayActivities[0];
    //   this.selectActivity(a.id, a.type);
    // }
  };

  const getData = async (startDate, endDate) => {
    const data = await getActivities({
      after: getUnixTime(startDate),
      before: getUnixTime(endDate)
    });

    const newActivities = Object.values(data).map((a) => {
      const date = parseJSON(a.start_date);
      return {
        ...a,
        start_date: date
      };
    });

    setActivities(newActivities);

    handleDateChange(chosenDate);
  };

  const handleMonthChange = (date) => {
    const startOfNextMonth = startOfMonth(add(new Date(), { months: 1 }));

    getData(date, startOfNextMonth);
  };

  const selectActivity = (activityId, activityType) => {
    if (onSelect) onSelect(activityId, activityType);
  };

  if (activities.length === 0) getData(startOfThisMonth, now);

  return (
    <Paper>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          openTo="day"
          value={chosenDate}
          onChange={handleDateChange}
          onMonthChange={handleMonthChange}
          onYearChange={handleMonthChange}
          slots={{
            day: BadgedPickersDay
          }}
          slotProps={{
            actionBar: {
              actions: []
            },
            day: {
              activities
            }
          }}
        />
      </LocalizationProvider>
      {dayActivities.length > 0 && (
        <>
          <Divider />
          <List>
            {dayActivities.map((a) => {
              const icon = activityIcons[a.type];
              const title = a.name;
              const subTitle = `${(a.distance / 1000).toFixed(
                1
              )} km ${a.type.toLowerCase()}`;

              return (
                <ListItemButton
                  key={a.id}
                  onClick={() => selectActivity(a.id, a.type)}
                >
                  <ListItemAvatar>
                    <Avatar>{icon}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={title} secondary={subTitle} />
                </ListItemButton>
              );
            })}
          </List>
        </>
      )}
    </Paper>
  );
}

StravaActivityPicker.propTypes = {
  onSelect: PropTypes.func.isRequired
};

export default StravaActivityPicker;
