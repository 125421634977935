import React from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';

import { Typography, LinearProgress, Link } from '@mui/material';

import Grid from '@mui/material/Grid2';

import StravaActivityStatusIcon from './StravaActivityStatusIcon';

const dateText = (run) => {
  if (run.skipped) return '(skipped)';
  if (run.date) return format(run.date, 'EEE do');
  return '-';
};

function StravaRunRow({ run, i }) {
  return (
    <Grid key={`strava-row-${run.id}`} container justifyContent="space-between">
      <Grid size={3}>
        <StravaActivityStatusIcon run={run} i={i} fontSize="medium" />
      </Grid>
      <Grid size={6} alignSelf="start">
        <>
          <LinearProgress
            variant="determinate"
            value={
              run.timeInMins
                ? 100 * Math.min(run.timeInMins / run.targetMins, 1)
                : 0
            }
            sx={{ height: 15 }}
          />
          {run.id ? (
            <Link
              href={`https://www.strava.com/activities/${run.id}`}
              target="_blank"
              underline="none"
              color="#fc5200"
            >
              <Typography>
                {run.distance &&
                  `${run.name} - ${(run.distance / 1000).toFixed(1)} km`}
              </Typography>
            </Link>
          ) : (
            <Typography>{`(${run.targetRange.join(' - ')} mins)`}</Typography>
          )}
        </>
      </Grid>
      <Grid size={3} alignSelf="start">
        <Typography variant="body1" noWrap>
          {dateText(run)}
        </Typography>
      </Grid>
    </Grid>
  );
}

StravaRunRow.propTypes = {
  run: PropTypes.shape({
    skipped: PropTypes.bool,
    id: PropTypes.number,
    start_date: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    week: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    distance: PropTypes.number,
    moving_time: PropTypes.number,
    timeInMins: PropTypes.number,
    targetMins: PropTypes.number,
    targetRange: PropTypes.arrayOf(PropTypes.number).isRequired,
    metTarget: PropTypes.bool,
    average_speed: PropTypes.number,
    averagePace: PropTypes.number,
    average_heartrate: PropTypes.string
  }).isRequired,
  i: PropTypes.number.isRequired
};

export default StravaRunRow;
