import React, { useState, useEffect } from 'react';

import { Select, MenuItem } from '@mui/material';

import moment from 'moment';
import chroma from 'chroma-js';

import Deck from '../components/deck';
import { getFlightData, getFlights } from '../api/drone';

const existsAndIsNotNull = (object, property) =>
  property in object && object[property] !== null;

const getLocationAddress = (doc) => {
  const skippedComponents = [
    'plus_code',
    'country',
    'administrative_area_level_1',
    'postal_code'
  ];

  if (
    doc !== null &&
    existsAndIsNotNull(doc, 'Location') &&
    existsAndIsNotNull(doc.Location, 'Address')
  ) {
    return doc.Location.Address.address_components
      .filter((c) => {
        let keep = true;

        skippedComponents.forEach((sc) => {
          if (c.types.includes(sc)) {
            keep = false;
          }
        });

        return keep;
      })
      .map((c) => c.short_name)
      .join(', ');
  }

  return '(unknown)';
};

const processFlights = async () => {
  try {
    const data = await getFlights();
    return data.map((doc) => ({
      id: doc.filename,
      date: moment(doc['Update Time'].$date),
      location: getLocationAddress(doc)
    }));
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getFlight = async (flightId) => {
  try {
    const data = await getFlightData({
      fileId: flightId,
      datasets: ['drone', 'app']
    });
    return data;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const filterPoints = (points) =>
  points.filter((p) => {
    const threshold = 1e-5;
    return (
      (p.Latitude > threshold || p.Latitude < -threshold) &&
      (p.Longitude > threshold || p.Longitude < -threshold)
    );
  });

const lonLatAlt = (p) => [p.Longitude, p.Latitude, p.Height];

const pointsToSegments = (points) =>
  points.slice(0, -1).map((p, i) => ({
    name: 'Mavic Air',
    start: lonLatAlt(p),
    end: lonLatAlt(points[i + 1]),
    speed: p.SpeedNorm
  }));

const processData = (points) => {
  const filtered = filterPoints(points);
  const segments = pointsToSegments(filtered);
  return segments;
};

const getColour = (d) => {
  const colourScale = chroma.scale(['black', 'red', 'yellow']);
  const rgba = colourScale(d.speed).rgba();
  rgba[3] *= 255;
  return rgba;
};

function Drone() {
  // const [loading, setLoading] = useState(true);
  const [flights, setFlights] = useState([]);
  const [selectedFlight, setSelectedFlight] = useState('');
  const [flightData, setFlightData] = useState({
    app: [],
    drone: []
  });

  // ToDo: Process names in backend.
  // const processName = (fileName) => {
  //   return fileName.replace('DJIFlightRecord_', '')
  //     .replace('.txt', '')
  //     .replace('_', ' ')
  //     .replace('[', '')
  //     .replace(']', '');
  // };

  useEffect(() => {
    if (flights.length === 0) {
      const updateFlights = async () => {
        console.log('Get flights list');
        const flightsList = await processFlights();

        if (flightsList) {
          setFlights(flightsList);
        }
      };

      updateFlights();
    }
  }, [flights]);

  useEffect(() => {
    if (flights.length > 0) {
      console.log('Select flight');
      setSelectedFlight(flights[0].id);
    }
  }, [flights]);

  useEffect(() => {
    if (selectedFlight) {
      const updateFlightData = async () => {
        console.log('Get flight data');
        const selectedFlightData = await getFlight(selectedFlight);
        console.log(selectedFlightData);

        if (
          selectedFlightData &&
          'drone' in selectedFlightData &&
          selectedFlightData.drone.length > 0
        ) {
          setFlightData({
            drone: processData(selectedFlightData.drone),
            app: processData(selectedFlightData.app)
          });
        }
      };

      updateFlightData();
    }
  }, [selectedFlight]);

  return (
    <>
      <Select
        value={selectedFlight}
        onChange={async (event) => {
          const flightId = event.target.value;
          setSelectedFlight(flightId);
        }}
        variant="outlined"
        sx={{
          zIndex: 1,
          position: 'absolute',
          left: '25px',
          backgroundColor: 'white',
          maxWidth: '80%'
        }}
      >
        {flights.map((o) => (
          <MenuItem key={o.id} value={o.id}>{`${o.date.format(
            'Do MMM YY'
          )} - ${o.location}`}</MenuItem>
        ))}
      </Select>
      <Deck
        data={flightData}
        colourFunction={getColour}
        primaryFeedName="drone"
      />
    </>
  );
}

export default Drone;
