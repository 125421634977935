import { makeRequest } from './base';

const userAuthorised = () => makeRequest('GET', 'fitbark/isAuthorised');
const storeAuthInfo = ({ code, scopes, state }) => {
  const reqParams = new URLSearchParams({
    code,
    scopes,
    state
  });

  const endpoint = `fitbark/auth?${reqParams}`;
  return makeRequest('GET', endpoint);
};

const getUserData = () => makeRequest('POST', 'fitbark/user');
const getActivityData = (data) => makeRequest('POST', 'fitbark/dog_data', data);
const getDogInfo = (data) => makeRequest('POST', 'fitbark/dog_info', data);
const getDogs = () => makeRequest('POST', 'fitbark/dogs');
const getPhoto = (data) => makeRequest('POST', 'fitbark/photo', data);

export {
  userAuthorised,
  storeAuthInfo,
  getUserData,
  getDogs,
  getDogInfo,
  getActivityData,
  getPhoto
};
