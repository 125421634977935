import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';

import ApiWebSocket from './ApiWebSocket';

import PlotlyFeatureChart from './PlotlyFeatureChart';

const styles = {
  selector: {
    zIndex: 3,
    position: 'absolute',
    top: '95px',
    left: '7%'
  }
};

function LiveEnergyPlot() {
  const [homeMiniData, setHomeMiniData] = useState([]);

  const processWsMessage = (message) => {
    const newData = message.map((item) => ({
      ...item,
      readAtDate: new Date(item.readAt)
    }));
    console.log(newData);
    // console.log(homeMiniData);

    if (newData) {
      const combinedData = [
        ...newData,
        ...homeMiniData.filter(
          (existingItem) =>
            newData.some(
              (newItem) => existingItem.readAt === newItem.readAt
            ) === false
        )
      ]
        .toSorted((a, b) => a.readAtDate - b.readAtDate)
        .slice(-1000);
      console.log(combinedData);
      setHomeMiniData(combinedData);
    }
  };

  return (
    <>
      <ApiWebSocket
        url={`${process.env.REACT_APP_API_WS_URL}/home-data/ws/energy/home-mini-readings`}
        messageReceived={(message) => processWsMessage(message)}
        updateFrequency={60}
        queryParams={{
          grouping: 'ONE_MINUTE'
        }}
        showIndicator={false}
      />
      {homeMiniData.length > 0 && (
        <PlotlyFeatureChart
          variable="Electricity Consumption"
          unit="kWh"
          series={{
            x: homeMiniData.map((item) => item.readAtDate),
            y: homeMiniData.map((item) => parseFloat(item.consumptionDelta))
          }}
          plotType="line"
          lineColour="orange"
        />
      )}
    </>
  );
}

LiveEnergyPlot.propTypes = {
  classes: PropTypes.shape({
    selector: PropTypes.string
  }).isRequired
};

export default withStyles(styles)(LiveEnergyPlot);
