import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DeckGL, { FlyToInterpolator, LineLayer } from 'deck.gl';
import { StaticMap, MapContext, NavigationControl } from 'react-map-gl';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const MAPBOX_TOKEN =
  'pk.eyJ1Ijoibmlja2VkeW5pY2siLCJhIjoiY2s4Z2UwY3V5MDBobjNmbXp0MmowYXZ6NiJ9.8-MIVQtuNsD2akCODAVC3Q';

const MAP_STYLE = 'mapbox://styles/mapbox/satellite-v9';
// const MAP_STYLE = 'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json';

const NAV_CONTROL_STYLE = {
  position: 'absolute',
  top: 10,
  right: 10
};

const INITIAL_VIEW_STATE = {
  latitude: 51.75,
  longitude: -2.31,
  zoom: 12
};

function Deck({ data, colourFunction, primaryFeedName }) {
  const [traceLayers, setTraceLayers] = useState(null);
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (data && data.drone && data.drone.length > 0) {
      const keys = Object.keys(data);

      if (keys.length > 0) {
        setTraceLayers(
          keys.map((key) => {
            const feed = data[key];

            return new LineLayer({
              id: key,
              data: feed,
              getSourcePosition: (d) => d.start,
              getTargetPosition: (d) => d.end,
              getColor: (d) => colourFunction(d),
              opacity: 0.8,
              widthMinPixels: 5,
              rounded: true,
              trailLength: feed.length * 1.25,
              currentTime: feed.length
            });
          })
        );

        const primaryFeed = data[primaryFeedName];

        setViewState({
          latitude:
            primaryFeed
              .map((p) => p.start[1])
              .reduce((total, x) => total + x, 0) / primaryFeed.length,
          longitude:
            primaryFeed
              .map((p) => p.start[0])
              .reduce((total, x) => total + x, 0) / primaryFeed.length,
          zoom: 17,
          pitch: 60,
          bearing: 0,
          transitionInterpolator: new FlyToInterpolator(),
          transitionDuration: 1000
        });
      }
    }
  }, [data]);

  return (
    <DeckGL
      initialViewState={viewState}
      onViewStateChange={(e) => setViewState(e.viewState)}
      controller={{
        touchRotate: true
      }}
      layers={traceLayers}
      ContextProvider={MapContext.Provider}
      style={
        mdUp
          ? {
              top: '68px',
              height: 'calc(100% - 68px)'
            }
          : {
              top: '56px',
              height: 'calc(100% - 56px)'
            }
      }
    >
      <StaticMap mapStyle={MAP_STYLE} mapboxApiAccessToken={MAPBOX_TOKEN} />
      <NavigationControl style={NAV_CONTROL_STYLE} />
    </DeckGL>
  );
}

Deck.propTypes = {
  data: PropTypes.shape({
    drone: PropTypes.arrayOf(
      PropTypes.shape({
        Longitude: PropTypes.number,
        Latitude: PropTypes.number,
        Height: PropTypes.number,
        SpeedNorm: PropTypes.number
      })
    ),
    app: PropTypes.arrayOf(
      PropTypes.shape({
        Longitude: PropTypes.number,
        Latitude: PropTypes.number,
        Accuracy: PropTypes.number,
        N: PropTypes.number
      })
    )
  }).isRequired,
  colourFunction: PropTypes.func.isRequired,
  primaryFeedName: PropTypes.string.isRequired
};

export default Deck;
