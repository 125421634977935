import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Grid from '@mui/material/Grid2';

import StravaRunRow from './stravaRunRow';
import StravaActivityStatusIcon from './StravaActivityStatusIcon';

function StravaWeekRuns({ week, runs, bestPace, defaultExpanded }) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <Accordion key={`card-week-${week}`} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon onClick={() => setExpanded(!expanded)} />}
        // sx={{
        //   cursor: 'unset !important'
        // }}
      >
        <Grid container size={12} spacing={2} justifyContent="space-between">
          <Grid size={4}>
            <Typography variant="h6" component="h2" gutterBottom>
              {`Week ${week}`}
            </Typography>
          </Grid>
          <Grid
            size={4}
            container
            columnSpacing={2}
            flexDirection="row"
            alignContent="center"
          >
            {runs.length > 0 &&
              !expanded &&
              runs.map(
                (run, i) =>
                  run.metTarget !== undefined && (
                    <Grid size={3}>
                      <StravaActivityStatusIcon
                        run={run}
                        i={i}
                        fontSize="medium"
                      />
                    </Grid>
                  )
              )}
          </Grid>
          <Grid size={4}>
            {runs.length > 0 && (
              <Typography variant="h6" component="h3">
                {bestPace && `${bestPace} / km`}
              </Typography>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {runs.length > 0 ? (
          <Grid container flexDirection="column" rowSpacing={1}>
            {runs.map((run, i) => (
              <StravaRunRow run={run} i={i} />
            ))}
          </Grid>
        ) : (
          <Typography fontStyle="italic" color="GrayText">
            No runs recorded so far
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

StravaWeekRuns.propTypes = {
  week: PropTypes.number.isRequired,
  runs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      start_date: PropTypes.string,
      date: PropTypes.instanceOf(Date),
      week: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
      distance: PropTypes.number,
      moving_time: PropTypes.number,
      timeInMins: PropTypes.number,
      targetMins: PropTypes.number,
      metTarget: PropTypes.bool,
      average_speed: PropTypes.number,
      averagePace: PropTypes.number,
      average_heartrate: PropTypes.number
    })
  ),
  bestPace: PropTypes.string,
  defaultExpanded: PropTypes.bool
};

StravaWeekRuns.defaultProps = {
  runs: [],
  bestPace: '',
  defaultExpanded: true
};

export default StravaWeekRuns;
