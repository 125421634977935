import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Paper, Button, Typography } from '@mui/material';

import { getApiToken } from '../../api/base';

function GenericAuthButton({
  name,
  userAuthorisedFunction,
  storeAuthFunction,
  stateKey,
  authUrlFunction,
  colourName,
  icon,
  location,
  displayLogout
}) {
  const [authorised, setAuthorised] = useState(true);

  const storeAuth = async () => {
    const params = new URLSearchParams(location.search);

    if (params.get('state').startsWith(stateKey)) {
      const reqData = {
        code: params.get('code'),
        scopes: params.get('scope'),
        state: params.get('state').replace(`${stateKey}_`, '')
      };
      console.log(reqData);

      const data = await storeAuthFunction(reqData);
      const { protocol, hostname, port, pathname } = window.location;
      if (data.status && data.status === 'success') {
        const redirectUrl = `${protocol}//${hostname}:${port}${pathname}`;
        console.log(redirectUrl);
        window.location.replace(redirectUrl);
      }
    }
  };

  const checkAuthorisation = async () => {
    try {
      const data = await userAuthorisedFunction();
      if (data.authorised === true) {
        setAuthorised(data.authorised);
      } else {
        console.error('User not authorised');
        setAuthorised(false);
      }
    } catch (err) {
      console.error(err);
      setAuthorised(false);
    }
  };

  const handleClick = async () => {
    const token = await getApiToken();
    const state = `${stateKey}_${token}`;
    const redirectUri = window.location.href;
    const authUrl = authUrlFunction(redirectUri, state);
    window.location.replace(authUrl);
  };

  if (location && location.search) storeAuth();

  useEffect(() => {
    checkAuthorisation();
  });

  if (authorised) {
    if (displayLogout)
      return (
        <Paper sx={{ p: 2, backgroundColor: `${colourName}.main` }}>
          {icon}
          <Typography>{`Logged in to ${name}!`}</Typography>
        </Paper>
      );

    return null;
  }

  return (
    <Button
      variant="contained"
      startIcon={icon}
      color={colourName}
      onClick={handleClick}
    >
      {`Login to ${name}`}
    </Button>
  );
}

GenericAuthButton.propTypes = {
  name: PropTypes.string.isRequired,
  userAuthorisedFunction: PropTypes.func.isRequired,
  storeAuthFunction: PropTypes.func.isRequired,
  stateKey: PropTypes.string.isRequired,
  authUrlFunction: PropTypes.func.isRequired,
  colourName: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  displayLogout: PropTypes.bool
};

GenericAuthButton.defaultProps = {
  location: null,
  displayLogout: true
};

export default GenericAuthButton;
