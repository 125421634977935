import React, { useState, useEffect } from 'react';

import {
  getISOWeek,
  getUnixTime,
  getISODay,
  sub,
  startOfISOWeek,
  parseJSON
} from 'date-fns';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Grid from '@mui/material/Grid2';

import StravaPlanProgress from '../components/stravaPlanProgress';
import StravaWeekRuns from '../components/stravaWeekRuns';
import { getTrainingPlan } from '../api/training';
import StravaSuggestedActivities from '../components/stravaSuggestedActivities';
import { decimalToBaseSixty } from '../utils/timeUtils';
import StravaActivitySummary from '../components/stravaActivitySummary';

import {
  getRuns,
  getRunningRoutes,
  combinePlanWithRuns,
  getEventActivityById,
  getEventActivityByName,
  getLongestRun,
  estimateTime,
  bestRunPace,
  calculateRecentAveragePace,
  findRoutesForTime
} from '../services/trainingService';

const planName = 'Stroud Half Marathon 2024';

const leadInWeeks = 3;
const minCompleteWeeks = 1;

function Training() {
  const [isLoading, setLoading] = useState(true);
  const [planTarget, setPlanTarget] = useState(null);
  const [trainingPlanWeeks, setTrainingPlanWeeks] = useState([]);

  const [planWithData, setPlanWithData] = useState({});
  const [activityId, setActivityId] = useState(null);
  const [eventData, setEventData] = useState(null);

  const [averagePace, setAveragePace] = useState(null);
  const [runningRoutes, setRunningRoutes] = useState([]);
  const [totalProgress, setTotalProgress] = useState(0);
  const [estimatedTime, setEstimatedTime] = useState('(unknown)');
  const [nextTargetTime, setNextTargetTime] = useState(null);
  const [suggestedRoutes, setSuggestedRoutes] = useState({});
  const [planStartDate, setPlanStartDate] = useState(null);

  const now = new Date();
  const currentWeek = getISOWeek(now);

  useEffect(async () => {
    if (!planTarget && runningRoutes.length === 0) {
      const [trainingPlanData, routes] = await Promise.all([
        getTrainingPlan(planName),
        getRunningRoutes()
      ]);

      // eslint-disable-next-line camelcase
      const { activity_id, target, weeks } = trainingPlanData;
      // eslint-disable-next-line camelcase
      if (activity_id) setActivityId(activity_id);

      setPlanTarget({
        name: target.name,
        distance: target.distance,
        date: parseJSON(target.date)
      });
      setTrainingPlanWeeks(weeks.toReversed());

      setRunningRoutes(routes);
    }
  }, [planTarget, runningRoutes]);

  useEffect(async () => {
    if (
      planTarget &&
      trainingPlanWeeks.length > 0 &&
      runningRoutes.length > 0
    ) {
      const targetWeek = getISOWeek(planTarget.date);
      const trainingPlan = Object.fromEntries(
        trainingPlanWeeks.map(({ times }, i) => [targetWeek - i, times])
      );

      const startDate = startOfISOWeek(
        sub(planTarget.date, {
          weeks: trainingPlanWeeks.length - 1
        })
      );
      const startWeek = getISOWeek(startDate);

      const displayStartDate =
        currentWeek - startWeek > minCompleteWeeks
          ? startDate
          : sub(startDate, { weeks: leadInWeeks });
      setPlanStartDate(displayStartDate);
      const displayStartWeek = getISOWeek(displayStartDate);

      const activities = await getRuns(
        getUnixTime(displayStartDate),
        getUnixTime(now)
      );

      const planWithRuns = combinePlanWithRuns(
        trainingPlan,
        activities,
        displayStartWeek,
        targetWeek
      );
      setPlanWithData(planWithRuns);

      const completedRuns = Object.values(planWithRuns)
        .flat()
        .filter((run) => run.targetMins && run.metTarget);
      const planTimes = Object.values(trainingPlan).flat();
      const completedRunsTime = completedRuns.reduce(
        (total, run) => total + run.targetMins,
        0
      );
      const totalPlanTime = planTimes.reduce(
        (total, runTarget) => total + runTarget.min,
        0
      );
      const planCompleted = completedRunsTime / totalPlanTime;
      setTotalProgress(100 * planCompleted);

      let eventActivity;
      if (activityId)
        eventActivity = getEventActivityById(activities, activityId);

      if (!eventActivity)
        eventActivity = getEventActivityByName(activities, planTarget.name);

      if (eventActivity && eventActivity.id) {
        setEventData(eventActivity);

        // ToDo: Load actual data here. Probably needs some backend attention.
        // const eventActivityData = await getActivityData(eventActivity.id);
        // console.log(eventActivityData);
        // setEventData(eventActivityData);
      }

      setLoading(false);
    }
  }, [planTarget, trainingPlanWeeks, runningRoutes]);

  useEffect(async () => {
    if (
      ((planWithData[currentWeek] && planWithData[currentWeek].length > 0) ||
        (planWithData[currentWeek - 1] &&
          planWithData[currentWeek - 1].length > 0)) &&
      !averagePace
    ) {
      let estimateRuns = [];
      if (planWithData[currentWeek]?.some((run) => run.timeInMins)) {
        estimateRuns = planWithData[currentWeek];
      } else if (planWithData[currentWeek - 1]?.some((run) => run.timeInMins)) {
        estimateRuns = planWithData[currentWeek - 1];
      }

      const currentLongestRun = getLongestRun(estimateRuns);
      setAveragePace(currentLongestRun.averagePace);
    } else {
      const batchAveragePace = await calculateRecentAveragePace();
      setAveragePace(batchAveragePace);
    }
  }, [planWithData]);

  useEffect(() => {
    if (planTarget && averagePace) {
      console.log(planTarget);
      // console.log(averagePace);
      const estTime = estimateTime(planTarget.distance, averagePace);
      // console.log(estTime);
      const estimateTimeString = decimalToBaseSixty(estTime / 60);
      setEstimatedTime(estimateTimeString);
    } else {
      setEstimatedTime('1:45:00');
    }
  }, [averagePace, planTarget]);

  const getNextTargetTime = () => {
    const nextRuns = Object.values(planWithData)
      .flat()
      .filter((run) => !run.distance && !run.skipped);

    if (nextRuns.length === 0) return [];

    return nextRuns[0].targetRange;
  };

  useEffect(() => {
    if (Object.keys(planWithData).length > 0 && new Date() < planTarget.date) {
      const targetRange = getNextTargetTime();
      setNextTargetTime(targetRange);
    }
  }, [planWithData]);

  useEffect(() => {
    if (nextTargetTime && averagePace) {
      const routes = findRoutesForTime(
        runningRoutes,
        nextTargetTime,
        planTarget.name,
        averagePace
      );
      setSuggestedRoutes(routes);
    }
  }, [nextTargetTime, averagePace]);

  return (
    <Grid
      container
      size={{ xs: 12, md: 10, lg: 8, xl: 6 }}
      spacing={2}
      justifyContent="center"
      sx={{ width: '100%' }}
    >
      {!isLoading && (
        <>
          <Grid size={{ xs: 12, sm: 10, md: 6 }}>
            <StravaPlanProgress
              progress={totalProgress}
              estimatedTime={estimatedTime}
              target={planTarget}
              startDate={planStartDate}
            />
          </Grid>
          {new Date() < planTarget.date && eventData === null ? (
            <Grid size={{ xs: 12, sm: 10, md: 6 }}>
              <StravaSuggestedActivities
                targetTime={nextTargetTime}
                routes={suggestedRoutes}
              />
            </Grid>
          ) : (
            <Grid size={{ xs: 12, sm: 10, md: 6 }}>
              <StravaActivitySummary eventData={eventData} />
            </Grid>
          )}
          {Object.entries(planWithData)
            .toReversed()
            .map(([week, runs]) => {
              const weekNum = parseInt(week);
              let bestWeekPace;
              if (runs.length > 0) {
                const bestPace = bestRunPace(runs);
                if (bestPace !== Infinity)
                  bestWeekPace = decimalToBaseSixty(bestPace);
              }
              return (
                weekNum <=
                  currentWeek + (getISODay(new Date()) > 4 ? 1 : 0) && (
                  <Grid
                    size={{ xs: 12, sm: 10, md: 6 }}
                    key={`strava-week-${weekNum}`}
                  >
                    <StravaWeekRuns
                      week={
                        weekNum -
                        getISOWeek(planTarget.date) +
                        trainingPlanWeeks.length
                      }
                      runs={runs}
                      bestPace={bestWeekPace}
                      defaultExpanded={weekNum === currentWeek}
                    />
                  </Grid>
                )
              );
            })}
        </>
      )}
      <Grid size={12}>
        <Backdrop sx={{ color: '#fff', zIndex: 4 }} open={isLoading}>
          <CircularProgress size={100} color="inherit" />
        </Backdrop>
      </Grid>
    </Grid>
  );
}

export default Training;
